<template>
  <v-card class="pa-4" flat>
    <div class="d-flex align-center flex-wrap ">
      <v-select
        v-model="queryData.school_year"
        :items="g_master_data_siswa.tahun_ajaran"
        :label="$t('journal.school_year')"
        :class="$vuetify.breakpoint.smAndUp && 'select-175'"
        outlined
        hide-details
        dense
        class="caption ma-2"
      />
      <v-select
        v-model="queryData.class"
        :items="g_master_data_siswa.siswa.pilih_kelas"
        :label="$t('journal.class')"
        :disabled="!queryData.school_year"
        :class="$vuetify.breakpoint.smAndUp && 'select-175'"
        outlined
        hide-details
        dense
        class="caption ma-2"
      />
      <v-select
        v-model="queryData.class_type"
        :items="g_master_data_siswa.siswa.pilih_kelas"
        :label="$t('journal.class_type')"
        :disabled="!queryData.class"
        :class="$vuetify.breakpoint.smAndUp && 'select-175'"
        outlined
        hide-details
        dense
        class="caption ma-2"
      />

      <div class="font-weight-bold subtitle-2">
        {{ total }} {{ $t("app.student") }}
      </div>

      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
    </div>

    <v-divider class="mb-4 mt-3"></v-divider>

    <v-data-table
      :headers="tableHeaders"
      :items="dataTable"
      :page.sync="queryData.page"
      :items-per-page="queryData.limit"
      :loading="loading"
      hide-default-footer
      class="elevation-0"
    >
      <template v-slot:item.action="{ item }">
        <v-btn icon small dark class="primary mr-2" @click="viewWeekly(item)">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <Pagination
      :length="length"
      :total="total"
      :current-page="queryData.page"
      :limit="queryData.limit"
      :handler="paginateChange"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/i18n";
import moment from "moment/moment";
moment.locale("id");

import Pagination from "@/components/Pagination";

export default {
  metaInfo: {
    title: i18n.t("routes.weekly_report"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: {
    Pagination
  },
  computed: mapGetters(["g_master_data_siswa"]),
  data() {
    return {
      currentDate: "",
      loading: false,
      bkNotes: false,
      length: 0,
      total: 0,
      tableHeaders: [
        {
          text: i18n.t("weekly.table.name"),
          value: "name"
        },
        {
          text: i18n.t("weekly.table.approved"),
          value: "approved"
        },
        {
          text: i18n.t("weekly.table.shared"),
          value: "shared"
        },
        {
          text: i18n.t("weekly.table.rejected"),
          value: "rejected"
        },
        {
          text: i18n.t("weekly.table.deleted"),
          value: "deleted"
        },
        {
          text: i18n.t("app.action"),
          value: "action",
          sortable: false,
          width: 100
        }
      ],
      queryData: {
        page: 1,
        limit: 10,
        sort: "id",
        order: "DESC",
        search: "",
        school_year: "",
        class: "",
        class_type: ""
      },
      dataTable: [],
      modaladdnotes: false,
      form_notes: {
        type: "1",
        date: "",
        file: "",
        notes: "",
        action: ""
      }
    };
  },
  watch: {
    currentDate(param) {
      if (this.queryData.school_year) {
        this.queryData.search = moment(param).format("YYYY-MM-DD");
        this.initData();
      }
    },
    "queryData.class_type"() {
      this.initData();
    }
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.loading = true;
      this.dataTable = [];
      setTimeout(() => {
        this.dataTable = [
          {
            id: 1,
            name: "Marion Jola",
            approved: 1,
            shared: 2,
            rejected: 4,
            deleted: 3
          },
          {
            id: 2,
            name: "Marion Jola 2",
            approved: 4,
            shared: 2,
            rejected: 4,
            deleted: 3
          }
        ];
        this.loading = false;
      }, 500);
    },
    paginateChange(page, limit) {
      this.page = page;
      this.limit = limit;
      this.initData();
    },
    viewWeekly(item) {
      this.$router.push({
        path: "weekly-report-detail",
        query: { id: item.id }
      });
    }
  }
};
</script>

<style></style>
